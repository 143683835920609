import React from "react";

export default function Footer() {
  return (
    <footer className="relative bg-mid_color text-back_color">
      <div className="flex flex-wrap justify-center">
        <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 text-center">
          <h2 className="title-font font-semibold text-back_color tracking-widest text-s mb-1">
          &copy; {new Date().getFullYear()} Manoj Singireddy. All Rights Reserved.
          </h2>
        </div>
        
      </div>
      
    </footer>
  );
}
