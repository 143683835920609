import About from "./About";
import Projects from "./Projects";
import Skills from "./Skills";
import Experiences from "./Experiences";

import React from "react";

export default function Portfolio() {
  return (
    <main>
      <About />
      <Projects />
      {/* <Experiences /> */}
      <Skills />
    </main>
  );
}
