import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

export default function LockFreeAnalysis() {
    return (
        <main className="text-front_color bg-back_color body-font">
            <div className="container mx-auto px-4 py-24">
                <h1 className="text-4xl font-medium mb-4">
                    Lock Free Programming Analysis
                </h1>
                <h2 className="text-2xl font-medium mb-2 text-mid_color">
                    Comparing performance of Lock-Free Data Structures with standard Data Structures
                </h2>
                <a
                    href="https://github.com/Manojrsingireddy/Lock-Free-Programming-Analysis"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-mid_color mb-2 text-front_color hover:bg-front_color hover:text-back_color font-bold py-2 px-4 rounded-full inline-block mt-2 mr-2 transition duration-300"
                >
                    <FontAwesomeIcon icon={faGithub} className="mr-2" />
                    Source
                </a>
                <div className="flex">
                    <div className="flex">
                        {/* Left Container with Text */}
                        <div className="pr-8">

                            <p className="text-lg mb-2 font-bold">Description</p>
                            <p className="text-base mb-4">
                                This project documents my endeavor to implement a series of lock-free algorithms, a class of algorithms wherein concurrent code is developed to safeguard critical sections without blocking primitives like mutexes or semaphores. Specifically, I created lock-free versions of a stack, hashtable, and binary search tree. Subsequently, I conducted a comprehensive performance evaluation compared to their traditional counterparts.
                            </p>     
                            <p className="text-lg mb-2 font-bold">Stack</p>
                            <p className="text-base mb-4">
                                I began by creating a lock-based stack using a mutex for basic thread safety, serving as a reference point for my lock-free stack implementation. This lock-based stack operated like a conventional stack, with functions like constructor, destructor, push, and pop, but included the mutex to protect stack node access.
                                To achieve lock-free operations, I heavily relied on the "compare and swap" (CAS) or "compare and exchange" methods (in C++ terms) with atomic variables. CAS checks if the value in an atomic variable matches the expected value and replaces it with the new value if it fits. This atomic operation is pivotal in ensuring thread safety without traditional locks. I opted for the "weak variant" of CAS for my stack operations.
                                For the pop method, I continually attempted to remove the top element using CAS until I succeeded, returning the value once successful. In contrast, the push method involved continuously adding a new element to the top and setting it as the new top while marking the next variable as the current node.
                                The lock-free stack I implemented performed well with increasing thread counts but struggled with high operation concurrency. While lock-free stacks are effective in specific scenarios, their suitability should be assessed based on particular concurrency requirements and potential contention on CAS instructions.
                            </p>  
                            <p className="text-lg mb-2 font-bold">Hash Table</p>
                            <p className="text-base mb-4">
                                I also implemented a hash table for my project, a data structure known for efficient storage and retrieval based on unique keys through hashing. It uses hashing to map keys to specific table locations, ensuring constant search time regardless of element count. Collisions are resolved through chaining or linear probing, where multiple keys generate the same hash index. To benchmark and serve as a basis, I initially created a lock-based hash table, using mutexes for thread safety. Subsequently, I designed a lock-free version inspired by Maged M. Michael's paper, employing atomic CAS instructions. Chaining was particularly beneficial, storing linked lists at each hash index to avoid the complexities of linear probing.
                                The insert method involved creating a new node and checking the corresponding hash's linked list for matching keys. If found, I returned early; otherwise, I attempted to add the new node to the list, akin to the stack push operation. The get method required no CAS instruction, iterating through the list and returning a value if found, as get operations can interleave. However, it didn't handle inserts occurring after its check. The remove method was similar to the stack pop, finding the correct list and removing the node corresponding to the key. The lock-free hash table demonstrated performance benefits, especially with increased operations per thread. It proved advantageous for scenarios requiring parallel hash table usage, showcasing its potential as a valuable data structure implementation.
                            </p>  
                            <p className="text-lg mb-2 font-bold">Binary Search Tree</p>
                            <p className="text-base mb-4">
                                I implemented a Binary Search Tree (BST) as my final data structure. The insert, remove and get methods are straightforward in a lock-based approach. For insertion, I lock the entire method while finding the correct location for the key and inserting it. Retrieving a node (get) involves a simple tree traversal, and removal (remove) requires replacing the node with its appropriate children based on its keys.
                                The real challenge arose when implementing the lock-free algorithm, inspired by a paper by Nathan G. Bronson and others. This approach involved various helper methods to attempt to get, remove, and insert operations, which could be retried frequently. Each method encapsulated its actions into a data structure and iterated while trying to complete its actions via CAS operations. The lock-free BST proved highly appealing, especially for use cases with a high volume of writes and fewer reads. As observed with the hash table, the benefits of the lock-free approach became increasingly evident as concurrent work per thread escalated.
                            </p>    
                            <p className="text-lg mb-2 font-bold">Learnings</p>
                            <p className="text-base mb-4">
                                Throughout the implementation of a stack, binary search tree (BST), and hash table, I've gained valuable insights into the world of concurrent data structures. First and foremost, I've come to appreciate the significance of lock-free algorithms in enabling parallelism while minimizing contention. In the stack implementation, I learned the importance of utilizing atomic operations like CAS for thread safety. I observed how their performance can be influenced by the number of threads and operations per thread.
                                The hash table implementation demonstrated the power of lock-free data structures, with substantial performance benefits arising from parallelism and reduced contention. Choosing between chaining and linear probing for handling collisions became crucial, emphasizing the significance of selecting the right strategy for a given use case. Similarly, The BST implementation highlighted the complexity of creating lock-free data structures, especially in cases where multiple threads contend for the same resources. I've learned that lock-free algorithms can exhibit remarkable scalability when executed correctly, as seen in the BST's performance improvements with increasing thread count and operation concurrency.
                                In conclusion, my journey through these data structures underscored the nuanced decision-making involved in selecting and implementing concurrent data structures. Lock-free algorithms have the potential to unlock impressive performance gains, but their effectiveness depends on factors like thread count, operation concurrency, and the nature of the data structure itself. These experiences have broadened my understanding of concurrent programming and the intricacies of creating efficient, thread-safe data structures to meet specific application requirements.
                            </p>                       

                        </div>

                    </div>
                </div>          


            </div>
        </main>
    );
}
