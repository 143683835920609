import React from "react";

export default function Navbar() {
  return (
    <header className="bg-mid_color md:sticky top-0 z-10">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a href="/#about" className="title-font font-medium text-white mb-4 md:mb-0 ml-3 text-xl hover:text-back_color">
          Manoj Singireddy    
        </a>
        <nav className="text-white md:ml-auto md:mr-3 md:py-1 md:pr-4 md:border-gray-700 flex flex-wrap items-center text-base justify-center">
          <a href="/#projects" className="mr-5 hover:text-back_color">
            Projects
          </a>
          {/* <a href="/#experiences" className="mr-5 hover:text-back_color">
            Experiences
          </a> */}
          <a href="/#skills" className="mr-5 hover:text-back_color">
            Skills
          </a>
        </nav>
      </div>
    </header>
  );
}
