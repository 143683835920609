import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

export default function ConveresationAnalysis() {
    return (
        <main className="text-front_color bg-back_color body-font">
            <div className="container mx-auto px-4 py-24">
                <h1 className="text-4xl font-medium mb-4">
                    iMessage Conversation Analysis
                </h1>
                <h2 className="text-2xl font-medium mb-2 text-mid_color">
                    Analyzing iMessages to Generate Insightful Reports
                </h2>
                <a
                    href="https://github.com/Manojrsingireddy/ConversationAnalysis"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-mid_color mb-2 text-front_color hover:bg-front_color hover:text-back_color font-bold py-2 px-4 rounded-full inline-block mt-2 mr-2 transition duration-300"
                >
                    <FontAwesomeIcon icon={faGithub} className="mr-2" />
                    Source
                </a>
                <div className="flex">
                    <div className="flex w-2/3">
                        {/* Left Container with Text */}
                        <div className="pr-8">

                            <p className="text-lg mb-2 font-bold">Description</p>
                            <p className="text-base mb-4">
                                I developed this project to generate learnings and insights through an exhaustive examination of the SQLite database of iMessage information found on Macbook computers. This database is the repository for all messages sent and received by an iCloud account, presenting a wealth of data that can be analyzed to derive meaningful reports and insights.
                                The core of my project revolves around two pivotal areas of analysis, each informed by my deepening understanding of the dataset.
                            </p>

                            <p className="text-lg mb-2 font-bold">Time of Day Analysis</p>
                            <p className="text-base mb-4">
                                This analysis scrutinizes message activity patterns by categorizing messages into different times of the day and visualizing their distribution. My journey through the dataset revealed the importance of timestamps, which I now expertly extract from the SQLite database. Subsequently, I crafted both pie charts to illustrate message counts and their respective percentages during morning, afternoon, evening, and night.
                            </p>

                            <p className="text-lg mb-2 font-bold">Profanity Analysis</p>
                            <p className="text-base mb-4">
                                The evolution of my project led me to explore the prevalence of offensive language within text messages. By querying the database for user-sent messages, I identify profanity with precision and calculate the percentage of profane words within individual words and messages. To present these findings effectively, I employ two pie charts, offering a graphical representation of the proportions of swear words among words and messages.
                            </p>

                            <p className="text-lg mb-2 font-bold">Learnings</p>
                            <p className="text-base mb-4">
                                The incorporation of my newfound knowledge into these analytical approaches has transformed them into powerful tools for extracting profound insights into communication habits. The Time of Day Analysis now provides nuanced insights into messaging patterns throughout the day, offering a more comprehensive understanding of temporal message distribution. Meanwhile, the Profanity Analysis allows me to gauge the prevalence of offensive language in text messages, casting a spotlight on the tone and content of these communications.
                            </p>

                            <p className="text-base mb-4">
                                In conclusion, this project stands as a testament to the iterative nature of learning and development. Through the application of my insights, I have harnessed the potential of the SQLite database on Macbook computers to provide users with a sophisticated means of comprehending and analyzing their communication habits in greater depth and detail.
                            </p>

                            {/* You can add more text, bullet points, or any other content here */}
                        </div>

                    </div>
                    <div className="w-1/3">
                        <img src="./TMDPercent.png" className="mb-8" alt="Time of Day Percentage" />
                        <img src="./ProfUsage.png" alt="Profanity Usage Percentage"/>
                    </div>
                </div>


            </div>
        </main>
    );
}
