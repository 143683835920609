import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

export default function About() {
  return (
    <section id="about">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
            Welcome to my Portfolio!
            <br className="hidden lg:inline-block" />
          </h1>
          <p className="mb-8 leading-relaxed">
            I'm a student enrolled in the Texas CSB Honors Program at University of Texas at Austin. My academic journey has been complemented by internships with several prominent organizations and various personal projects. These experiences have expanded my skill set and deepened my understanding of technology's real-world applications.
            </p>
            <p className="mb-8 leading-relaxed">
            My versatile skill set spans diverse technologies, equipping me for industry demands. With a strong foundation in both Computer Science and Business, I'm prepared to make meaningful contributions.
            </p>            

          {/* GitHub and LinkedIn buttons wrapped in a div */}
          <div className="flex">
            <a
              href="https://github.com/manojrsingireddy"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-mid_color text-front_color hover:bg-front_color hover:text-back_color font-bold py-2 px-4 rounded-full inline-block mt-2 mr-2 transition duration-300"
            >
              <FontAwesomeIcon icon={faGithub} className="mr-2" />
              GitHub
            </a>
            <a
              href="https://www.linkedin.com/in/manoj-singireddy/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-mid_color text-front_color hover:bg-front_color hover:text-back_color font-bold py-2 px-4 rounded-full inline-block mt-2 mr-2 transition duration-300"
            >
              <FontAwesomeIcon icon={faLinkedin} className="mr-2" />
              LinkedIn
            </a>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src="./my-pic.jpg"
          />
        </div>
      </div>
    </section>
  );
}