
import Navbar from "./components/Navbar";
import Portfolio from "./components/PortfolioHome";
import Footer from "./components/Footer";
import StepByStep from "./components/ProjectPages/StepByStep";
import SkinLesionClassification from "./components/ProjectPages/SkinLesionClassification";
import ConveresationAnalysis from "./components/ProjectPages/ConversationAnalysis";
import FraudOrNot from "./components/ProjectPages/FraudOrNot";
import NotFound from "./components/NotFound";
import LockFreeAnalysis from "./components/ProjectPages/LockFreeAnalysis";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from "react";





export default function App() {
  return (

    <main className="text-front_color bg-back_color body-font">      
    
      <Router>
            <Navbar />
            <Routes>
                <Route exact path='/' element={<Portfolio />} />
                <Route path='/stepbystep' element={<StepByStep />} />
                <Route path='/skinlesionclassification' element={<SkinLesionClassification />} />
                <Route path='/conversationanalysis' element={<ConveresationAnalysis />} />
                <Route path='/fraudornot' element={<FraudOrNot />} />
                <Route path='/lockfreeanalysis' element={<LockFreeAnalysis />} />
                <Route path='/*' element={<NotFound />} />
            </Routes>
            <Footer />
        </Router>
    </main>
  );
}
