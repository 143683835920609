export const projects = [
  {
    title: "Step By Step",
    subtitle: "Java and React.js",
    description:
      "I developed a user-friendly application for individuals to log their health data and receive personalized daily health goals generated by AI. The app seamlessly integrates a back-end API and a scalable NoSQL database to enhance user engagement and well-being.",
    link: "/stepbystep",
  },
  {
    title: "Skin Lesion Classification",
    subtitle: "Python, Tensorflow, and HTML/CSS/JS",
    description:
      "I used AI and Computer Vision to detect melanoma and other skin lesions, especially in underserved rural areas. My solution, achieving over 93% accuracy, offers accessible early diagnosis and intervention.",
    link: "/skinlesionclassification",
  },
  {
    title: "Lock Free Programming Analysis",
    subtitle: "C++ and Python",
    description:
      "In this project, I undertook the challenge of creating lock-free versions of a stack, hashtable, and binary search tree, avoiding the reliance on blocking primitives such as mutexes. I then conducted performance evaluations, comparing these lock-free implementations with their traditional counterparts, to gain insights into the benefits and limitations of lock-free algorithms in concurrent programming.",
    link: "/lockfreeanalysis",
  },
  {
    title: "iMessage Conversation Analysis",
    subtitle: "Python and SQL",
    description:
      "I designed the Conversation Analysis Program as a tool for Mac users who use iMessage on their Macs to analyze their conversations. It supports tasks like analyzing time differences, profanity usage, and time of day when most messages are sent or received.",
    link: "/conversationanalysis",
  },
  {
    title: "Fraud or Not Job Postings",
    subtitle: "R and Machine Learning",
    description:
      "I completed the project, which revolves around detecting fraudulent job listings from a dataset of job postings. The project involved data preprocessing, exploratory data analysis, and the application of machine learning techniques.",
    link: "/fraudornot",
  },
];

export const experiences = [
  {
    role: "Software Engineer Intern",
    company: "Bank of America",
    date: "Jun 2023 - Present",
    location: "Dallas, Texas, United States",
    description: [
      ""
    ],
    skills: [
      "Java",
      "SpringBoot",
      "AngularJS",
      "TypeScript",
      "Python",
    ],
    logo: "./BofA-Logo.svg",
  },
  {
    role: "Full-Stack Software Engineer Intern",
    company: "Jcore Systems",
    date: "Nov 2023 - Apr 2024",
    location: "San Antonio, Texas, United States",
    description: [
      "",
    ],
    skills: [
      "JavaScript",
      "Node.js",
      "React.js",
      "Python",
      "NATS",
      "Large Language Models",
    ],
    logo: "./jcore.svg",
  },
  {
    role: "Software Engineer Intern",
    company: "Chevron",
    date: "May 2023 - Jul 2023",
    location: "Houston, Texas, United States",
    description: [
      "Integrated two chemical management .NET solutions to provide $172,000 per year of business value in productivity increase",
      "Developed front-end solution using React and Chart.js for displaying dynamic data visualizations to promote ease of use",
      "Optimized business logic and ML model in Azure Databricks to promote runtime and save storage cost of $90,000 annually",
      "Deployed Azure resources using Ansible tools for a tool used by 200+ engineers, chemists, and managers globally",
    ],
    skills: [
      "Visual Basic .NET (VB.NET)",
      "Azure Databricks",
      "React.js",
      "Ansible",
      "Azure Data Factory",
    ],
    logo: "./Chevron-Logo.svg",
  },
  {
    role: "Software Engineer Intern",
    company: "Chevron",
    date: "May 2022 - Aug 2022",
    location: "Houston, Texas, United States",
    description: [
      "Developed and supported a project management tool to enable ~30% productivity increase for 50+ project managers",
      "Created Python converter of videos into event-based data to simulate use of an event-based camera (40% power savings)",
      "Demonstrated rapid POC of high-speed motion recognition and particle counting for potentially $100,000+ business value",
      "Facilitated rapid no-cost demo of underwater ROV inspection to address business problem of $5,000,000+ annual fuel waste",
    ],
    skills: [
      "Microsoft Power Apps",
      "Start-up Ventures",
      "Python (Programming Language)",
      "Research",
    ],
    logo: "./Chevron-Logo.svg",
  },
  {
    role: "Summer Apprentice",
    company: "Applied Research Laboratories, The University of Texas at Austin",
    date: "Jun 2021 - Aug 2021",
    location: "Austin, Texas, United States",
    description: [
      "Designed MATLAB algorithm to analyze sediment roughness to help US Navy sonar engineers interpret sonar data accurately",
      "Examined small-scale (micrometer) sediment roughness utilizing laser profiler scans to predict scattering of sonar waves",
      "Evaluated distance–variant resolution of laser profiler to determine maximum viable distance for ocean and land usage",
      "Presented work in Journal of Acoustical Society style report and earned Honorable Mention Award for presentation",
    ],
    skills: ["Research Design", "MATLAB"],
    logo: "./University-Logo.svg",
  },
  // {
  //   role: "Software Engineer Intern",
  //   company: "Grimm City Investors LLC",
  //   date: "Sep 2020 - May 2021",
  //   location: "New York, New York, United States",
  //   description: [
  //     "Researched environmentally sustainable initiatives to be implemented in a development proposal",
  //     "Created website using HTML and CSS",
  //   ],
  //   skills: ["HTML", "CSS"],
  //   logo: "./GrimmCity-Logo.svg",
  // },
  // {
  //   role: "Software Engineer Intern",
  //   company: "SV Solutions LLC",
  //   date: "May 2020 - May 2021",
  //   location: "New York, New York, United States",
  //   description: [
  //     "Designed in–app elastic search engine for TINDI app (helps users find nearby food trucks) in Java using Docker and NoSQL",
  //     "Developed programming infrastructure for TINDI app using SpringBoot to organize project structure",
  //   ],
  //   skills: ["SpringBoot", "Elastic Search", "Docker", "Java"],
  //   logo: "./SVSolutions-Logo.svg",
  // },
];




export const skills = [
  "JavaScript",
  "React",
  "Java",
  "Python",
  "SpringBoot",
  "MongoDB",
  "SQL",
  "Microsoft Power Apps",
  "Artificial Intelligence",
  "Large Language Models",
  "Docker",
  " C/C++",
  "C#",
  "R",
  "MATLAB",
  "ARM and x86",
  "Git",
  ".NET", 
  "Elasticsearch",
  "Unix/Linux",
];
