import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub} from "@fortawesome/free-brands-svg-icons";

export default function SkinLesionClassification() {
  return (
    <main className="text-front_color bg-back_color body-font">
      <div className="container mx-auto px-4 py-24">
        <div className="flex">
          {/* Left Container with Text */}
          <div className="pr-8">
            <h1 className="text-4xl font-medium mb-4">
              Skin Lesion Classification
            </h1>
            <h2 className="text-2xl font-medium mb-2 text-mid_color">
              Classification Application for 7 types of Skin Lesions
            </h2>
              <a
                href="https://github.com/Manojrsingireddy/SkinLesionClassification"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-mid_color mb-2 text-front_color hover:bg-front_color hover:text-back_color font-bold py-2 px-4 rounded-full inline-block mt-2 mr-2 transition duration-300"
              >
                <FontAwesomeIcon icon={faGithub} className="mr-2" />
                Source
              </a>
            <p className= "text-lg mb-2 font-bold">
              Description:
            </p>
            <p className="text-base mb-4">
            This project harnesses Artificial Intelligence and Computer Vision technology to confront a critical Image Classification challenge. At its core, this initiative aims to grapple with the diagnostic complexities surrounding melanoma and the differentiation of this condition from other skin lesions. The shortage of dermatologists and proficient medical practitioners capable of identifying such conditions poses a significant concern, especially given the prevalence of these dermatological issues. This challenge particularly plagues rural areas where melanoma prevention measures often fall short, consequently amplifying the risk of this disease.
The proposed solution aims to provide a cost-effective and swift means of detecting potential melanoma signs. The primary goal is to notify individuals of the necessity for consultation with a medical professional or dermatologist upon detecting any worrisome indicators. The provision of this solution at no cost assumes paramount importance as it directly addresses the common issue of individuals failing to recognize concerns related to melanoma when they observe skin lesions or marks. This lack of recognition frequently results in delayed medical attention, driven by the misconception that it might be a benign skin anomaly, such as a mole. In summary, this solution is meticulously designed to effectively alleviate these challenges and actively promote early detection and intervention for melanoma-related issues.
            </p>
            <p className= "text-lg mb-2 font-bold">
              Technologies/Methods:
            </p>
            <p className="text-base mb-4">
            The Artificial Intelligence model underwent training using an extensive dataset comprised of pre-classified instances of diverse skin lesions, encompassing Nevus, Dermatofibroma, Melanoma, Pigmented Bowen's, Pigmented Benign Keratoses, Basal Cell Carcinoma, and Vascular lesions. This dataset underwent rigorous preprocessing, including data cleaning and transformation through operations such as rotations and color swaps, to generate robust training data for the model.
The K Nearest Neighbors (KNN) Model was employed during the analysis phase as the initial approach. The KNN Model is a machine learning algorithm that leverages vectorized images as input and computes similarity scores against known reference samples. Subsequently, an enhanced approach was adopted, utilizing a UNET-based architecture with Transfer Learning techniques to refine the AI's proficiency in skin lesion classification.
UNET, a specialized Convolutional Neural Network (CNN) architecture designed for biomedical applications, played a pivotal role in this process. UNET employs a U-shaped architecture to process images, facilitating the segmentation of skin lesions from surrounding tissue. This segmentation process was followed by applying a pre-trained MobileNet model, a type of CNN architecture designed for efficient on-device vision applications. MobileNet reduces computational resource requirements while maintaining adequate accuracy.
The overall workflow entailed inputting a skin lesion image, utilizing UNET to isolate the lesion, processing the segmented image through the MobileNet model, and ultimately producing a lesion-type classification with an average accuracy exceeding 93%. It's important to note that the performance may vary across different images. The deployment of the model was realized through a straightforward HTML interface. Key technologies integral to the model's development included Tensorflow and the Keras Classifier.
This approach effectively harnessed the power of AI and CNNs to address the complex task of skin lesion classification, enhancing diagnostic capabilities for conditions like melanoma, Dermatofibroma, and others.

            </p>
            {/* <p className= "text-lg mb-2 font-bold">
              Key Learnings:
            </p>
            <p className="text-base mb-4">
            The project's key learnings revolve around the critical need to address healthcare disparities, particularly in dermatology, where a shortage of qualified practitioners poses a significant challenge. The project emphasizes the pivotal role of early detection and intervention in improving health outcomes by offering a rapid and cost-free method for identifying potential melanoma signs. Leveraging Artificial Intelligence (AI) and Convolutional Neural Networks (CNNs), specifically UNET-based architecture and Transfer Learning highlights their potential in biomedical applications. Rigorous data preprocessing is essential for building robust AI models, ensuring accurate differentiation of various skin lesion types. Moreover, effective model deployment through user-friendly interfaces enhances accessibility and usability. Lastly, the project underscores the iterative nature of AI model development, demonstrating the commitment to continuous improvement in refining and strengthening the system's accuracy. The project showcases AI's potential to bridge healthcare gaps and provide accessible solutions for early melanoma detection while emphasizing the importance of data quality, deployment, and ongoing refinement in healthcare technology.
            </p>
            You can add more text, bullet points, or any other content here */}
          </div>
          
        </div>
        <div className="flex justify-center items-center">
          <div className="w-2/3">
            <iframe
              src="https://docs.google.com/presentation/d/e/2PACX-1vTTPsTrILB-rh4gjNjjdhd57E0FoGicqMRhT4CppUDYozLpoSHvEZl-zHv4j9PrcHoZfjiG7JWl2u6L/embed?start=false&loop=false&delayms=3000"
              frameborder="0"
              width="100%" // Make the iframe width 100% to fit the container
              height="500vh" // Make the iframe height 100% to fit the container
              allowfullscreen="true"
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              title="skinslides"
            ></iframe>
          </div>
        </div>

      </div>
    </main>
  );
}
