import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

export default function FraudOrNot() {
    return (
        <main className="text-front_color bg-back_color body-font">
            <div className="container mx-auto px-4 py-24">
                <h1 className="text-4xl font-medium mb-4">
                    Fraud or Not Job Posting Analysis
                </h1>
                <h2 className="text-2xl font-medium mb-2 text-mid_color">
                    Analyzing Job Postings to Predict Fraudulent Job Postings
                </h2>
                <a
                    href="https://github.com/Manojrsingireddy/FraudOrNotJobPostings"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-mid_color mb-2 text-front_color hover:bg-front_color hover:text-back_color font-bold py-2 px-4 rounded-full inline-block mt-2 mr-2 transition duration-300"
                >
                    <FontAwesomeIcon icon={faGithub} className="mr-2" />
                    Source
                </a>
                <div className="flex">
                    <div className="flex ">
                        {/* Left Container with Text */}
                        <div className="pr-8">

                            <p className="text-lg mb-2 font-bold">Description</p>
                            <p className="text-base mb-4">
                                The project centers around the examination of a dataset containing job postings with the ultimate objective of detecting fraudulent job listings. Spanning multiple facets of data science, the project encompasses data preprocessing, exploratory data analysis (EDA), and the application of various machine learning techniques to predict deceptive job postings.
                                Data preprocessing is the initial phase of the project, focusing on addressing missing values, creating new variables to signify the presence of specific information, and converting categorical variables into factors for subsequent analysis. The EDA phase delves into exploring the data's characteristics, including correlations between various attributes and fraudulent job postings, visualized through histograms.
                                The heart of the project lies in model building, wherein four distinct machine learning techniques—Ridge Regression, Lasso Regression, Tree Bagging, and Neural Networks—are employed to predict fraudulent job postings. Each technique undergoes thorough evaluation, and confusion matrices are presented to gauge their respective performance.
                                The project's README provides clear instructions for potential users to replicate and extend the analysis. In sum, the project showcases a meticulous and insightful data analysis journey, integrating various data science techniques to address the pressing issue of detecting fraudulent job postings.
                            </p>                            

                        </div>

                    </div>
                </div>
            
            <div className="flex justify-center items-center">
          <div className="w-2/3">
            <iframe
              src="https://docs.google.com/presentation/d/e/2PACX-1vQBU-6mBnggGA9VYJ5F8GqVz8OQw2Nta2JJ_bnaX-G-N61LE3HMxMMMnXPHb0qhaw/embed?start=false&loop=false&delayms=3000"
              frameborder="0"
              width="100%" // Make the iframe width 100% to fit the container
              height="500vh" // Make the iframe height 100% to fit the container
              allowfullscreen="true"
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              title="fraudslides"
            ></iframe>
          </div>
        </div>


            </div>
        </main>
    );
}
